$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 780px,
  lg: 1100px,
  xl: 1200px,
  xxl: 1400px,
);
$container-max-widths: (
  sm: 540px,
  md: 840px,
  lg: 1160px,
  xl: 1260px,
  xxl: 1460px,
);

$font-sizes: (
  1: 2.5rem,
  // 24px
  2: 2rem,
  // 18px
  3: 1.5rem,
  // 16px
  4: 1.333rem,
  // 14px
  5: 1.167rem,
  // 12px
  6: 1rem,
  // 10px
  7: 0.833rem,
);

$enable-rounded: true;

$spacers: (
  0: 0,
  // 2px
  1: 0.167rem,
  // 4px
  2: 0.333rem,
  // 8px
  3: 0.667rem,
  // 16px
  4: 1.333rem,
  // 24px
  5: 2rem,
  // 32px
  6: 2.667rem,
  // 40px
  7: 3.333rem,
  // 48px
  8: 4rem,
);

$grid-gutter-width: 1.143rem;
