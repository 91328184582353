// --- FORMS ---
.ng-submitted .ng-invalid,
.ngb-timepicker.ng-touched.ng-invalid .ngb-tp-input,
.ng-touched.ng-invalid,
:invalid {
  border-color: var(--bs-danger);
}
// ---
.page-max-width {
  max-width: 900px;
}
.page-max-width-lg {
  max-width: 1200px;
}

.required::after {
  content: ' *';
  color: red;
}

.alto-badge-no-size {
  display: inline-block;
  border-radius: 25px;
  padding: 0 0.5rem;
  text-align: center;
  font-weight: 600;
}
.alto-score {
  @extend .alto-badge-no-size;
  min-height: 1.75rem;
  min-width: 3.2143rem;
  line-height: 1.75rem;
  font-weight: 600;
  font-size: 0.833rem;
}
.alto-badge {
  @extend .alto-badge-no-size;
  min-height: 1.66rem;
  min-width: 1.66rem;
  line-height: 1.66rem;
  font-size: 0.833rem;
  background-color: var(--blue-gray-50);
  color: var(--blue-gray-600);
  @media screen and (max-width: 820px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.orange {
    color: var(--warning-600);
    background-color: var(--warning-50);
  }

  &.red {
    color: var(--error-600);
    background-color: var(--error-50);
  }

  &.green {
    color: var(--success-600);
    background-color: var(--success-50);
  }

  &.gray {
    color: var(--gray-600);
    background-color: var(--gray-50);
  }

  &.primary {
    color: var(--primary-700);
    background-color: var(--primary-50);
  }
}

.alto-badge-big {
  @extend .alto-badge-no-size;
  height: 2.667rem;
  width: 2.667rem;
  line-height: 2.667rem;
  font-size: 2rem;
  color: var(--gray-900);
  background-color: var(--alto-medium-grey);
}

.alto-badge-blue {
  @extend .alto-badge-big;
  color: var(--bs-primary);
  background-color: #d1e9ff;
}
.alto-badge-red {
  @extend .alto-badge-big;
  color: #d92d20;
  background-color: #fee4e2;
}

.ellipsis-badge {
  max-width: 6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitles {
  color: var(--alto-text-second-color);
}

.panel {
  background-color: white;
  border-radius: 0.667rem;
  border: 1px solid var(--gray-200);
  padding: 1.667rem;
}

.nodata-placeholder {
  min-height: 12rem;
  background-color: var(--indigo-25);
  border-radius: 0.6667rem;
  padding: 4rem;
  margin: 2rem 0;
  a {
    width: fit-content;
    margin: 0 auto;
  }
  p {
    font-weight: 600;
    color: var(--indigo-900);
  }
  span {
    color: var(--indigo-900);
  }
}

.header-panel {
  background-color: var(--primary-50);
  border-radius: 0.67rem;
  padding: 1.333rem 2rem;
  min-height: 5.333rem;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 3.333rem;
  p {
    margin-top: 0.667rem;
    font-size: 1.1667rem;
    color: var(--gray-500, #667085);
  }
  h1 {
    margin: 0;
  }

  &.alert {
    justify-content: space-between;

    .main {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .alertMsg {
      display: flex;
      align-items: center;
      gap: 8px;

      i {
        color: var(--bs-danger);
      }

      p {
        margin: 0;
        color: var(--bs-danger);
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .header-panel {
    padding: 1.333rem;
  }
}

.header-panel2 {
  @extend .header-panel;
  background-color: var(--primary-25);
  height: 5.333rem;
  padding: 0 1.7rem;
  color: var(--alto-text-blue);

  h1 {
    display: inline-block;
    line-height: 5.33rem;
    font-weight: 600;
  }

  &.accelerated {
    color: var(--orange-600);
    background-color: var(--orange-50);
  }
}

h2 {
  .emoji {
    height: 1.667rem;
    margin-right: 0.667rem;
    vertical-align: bottom;
  }
}

// Block used while loading content
.alto-placeholder {
  min-height: 7.143rem;
  background-color: var(--alto-medium-grey);
  border-radius: 1.143rem;
}

.table-panel {
  background-color: white;
  border-radius: 0.667rem;
  border: 1px solid var(--alto-border);
  h2 {
    padding: 1.4rem 1.7rem;
    margin: 0;
  }
  .table {
    --bs-table-bg: transparent;

    th {
      font-weight: 600;
      font-size: 0.833rem;
      padding: 0.5rem;
    }
    thead tr {
      height: 2.667rem;
    }
    tbody tr {
      min-height: 3.333rem;
    }
    tr {
      & * {
        vertical-align: middle;
      }
      th:first-child,
      td:first-child {
        padding-left: 1.7rem;
      }
    }
  }
}

.table-no-last-line {
  tr:last-child {
    td {
      border: 0;
    }
  }
}

.vertical-divider {
  display: inline-block;
  border-left: 2px solid var(--alto-medium-grey);
  min-height: 2rem;
  vertical-align: middle;
}

// Used in modals
.close-button {
  padding: 0;
  width: 2.5rem;
  height: 2.5rem;
  i {
    font-size: 1.9rem;
    line-height: 2.5rem;
  }
}

.skeleton-panel {
  background-color: white;
  background: linear-gradient(110deg, white 8%, var(--gray-75) 18%, white 33%);
  background-size: 200% 100%;
  border-radius: 0.667rem;
  padding: 1.667rem;
  animation: 5s shine linear infinite;
}

.hover-red {
  &:hover {
    color: var(--error-600);
  }
}


/* Tooltip container */
.tooltip-container {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip-container .tooltip-text {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);

  &.top {
    top: -5px;
    bottom: auto;
    transform: translate(-50%, -50%);
  }

  &.large {
    width: 18rem;
  }

  &.error {
    background-color: var(--error-400);
  }

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip-container .tooltip-text::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;

  &.top {
    top: 100%;
    bottom: auto;
    border-color: #555 transparent transparent transparent;
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-container .tooltip-text.error::after {
  border-color: transparent transparent var(--error-400) transparent;
  color: white;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
