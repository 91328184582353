@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap-functions';
@import 'bootstrap/scss/bootstrap';
@import 'bootstrap-override';
@import 'bootstrap-icons';
@import '~@ng-select/ng-select/themes/default.theme.css';
@import 'base';
@import 'font';
@import 'common';
@import 'color';
